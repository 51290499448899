<template>
  <div>
    <el-dialog v-model="visible" :title="props.title" width="580px" :footer="null">
      <div class="goods-info">
        <div class="left">
          <img :src="props.goodsInfo.image[0]" alt="" />
        </div>
        <div class="right">
          <p class="title">
            {{ props.goodsInfo.title }}
          </p>
          <div class="district">
            <text>{{ goodsInfo.params[0].name }}：</text>
            <text>{{ goodsInfo.name }}- {{ goodsInfo.params[0].param_value }}</text>
          </div>
          <div class="price" v-if="props.title === '立即购买'">
            <text>商品价格：</text>
            <text>¥{{ goodsInfo.price / 100 }}</text>
          </div>
          <div class="price" v-if="props.title === '立即报名'">
            <text>保证金：</text>
            <text>¥{{ goodsInfo.deposit / 100 }}</text>
          </div>
        </div>
      </div>
      <el-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <el-form-item label="联系电话" prop="mobile">
          <el-input v-model="formState.mobile" placeholder="请输入您的手机号" />
        </el-form-item>
        <el-form-item label="联系QQ" prop="qq">
          <el-input v-model="formState.qq" placeholder="请输入您的QQ" />
        </el-form-item>
        <el-form-item label="联系微信" prop="weChat">
          <el-input v-model="formState.weChat" placeholder="请输入您的微信" />
        </el-form-item>
      </el-form>
      <div class="pay-btn" @click="payClick(formRef)" v-if="props.title === '立即购买'">
        立即支付
      </div>
      <div class="pay-btn" @click="payClick(formRef)" v-if="props.title === '立即报名'">
        缴纳保证金
      </div>
      <div v-if="props.title === '立即报名'" class="footer">
        <text>支付立即阅读并同意</text>
        <text>《交易意向金须知》</text>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, defineExpose, reactive, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { placeOrder } from '@/api/order'
const router = useRouter()
const visible = ref(false)
const formRef = ref()
const formState = reactive({
  mobile: '',
  qq: '',
  weChat: ''
})
const rules = {
  mobile: [
    { required: true, trigger: 'blur', message: '请输入手机号' },
    {
      required: true,
      min: 11,
      pattern: /^[1][0-9]{10}$/,
      message: '手机号格式不正确',
      trigger: 'blur'
    }
  ],
  qq: [{ required: true, trigger: 'blur', message: '请输入QQ' }],
  weChat: [{ required: true, trigger: 'blur', message: '请输入微信' }]
}
const props = defineProps({
  goodsInfo: Object,
  title: String
})
function payClick(formEl) {
  if (!formEl) return
  formEl.validate((valid, fields) => {
    if (valid) {
      formState.goods_id = props.goodsInfo.id
      if (props.title === '立即购买') {
        formState.amount = props.goodsInfo.price
        formState.type = 0
        router.push({
          path: '/pay',
          query: { params: JSON.stringify(formState), type: 'buy' }
        })
      } else if (props.title === '立即报名') {
        formState.amount = props.goodsInfo.price
        formState.deposit = props.goodsInfo.deposit
        formState.type = 4
        router.push({
          path: '/pay',
          query: { params: JSON.stringify(formState), type: 'partBidding' }
        })
      }
      // placeOrder(formState).then((res) => {
      //   if (res.code == 200) {
      //     if (props.title === '立即购买') {
      //       router.push({
      //         path: '/pay',
      //         query: { price: res.data.amount, orderNo: res.data.order_no, type: 'buy' }
      //       })
      //     } else if (props.title === '立即报名') {
      //       router.push({
      //         path: '/pay',
      //         query: {
      //           price: res.data.amount,
      //           orderNo: res.data.order_no,
      //           type: 'partBidding',
      //           id: props.goodsInfo.id,
      //           name: props.goodsInfo.name
      //         }
      //       })
      //     }
      //   }
      // })
    } else {
      console.log('error submit!', fields)
    }
  })
}
onMounted(() => {
  nextTick(() => {
    console.log(props.goodsInfo)
  })
})
defineExpose({ visible })
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  border-radius: 20px;
}
.el-dialog__header {
  text-align: center;
  border: 0;
  border-radius: 20px 20px 0 0;
  .el-dialog__title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
  }
}
.goods-info {
  display: flex;
  .left {
    img {
      width: 183px;
      height: 116px;
      border-radius: 12px;
    }
  }
  .right {
    margin-left: 12px;
    .title {
      width: 100%;
      word-break: break-word;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .district {
      margin-top: 8px;
      text:nth-child(1) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      text:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .price {
      margin-top: 8px;
      text:nth-child(1) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      text:nth-child(2) {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff211b;
      }
    }
  }
}
.el-form {
  margin-top: 16px;
  .el-form-item-label {
    width: 85px;
  }
  .el-form-item-control-input {
    border: 1px solid #e6e6e6;
    border-radius: 16px;
    padding: 0 18px;
    .el-input {
      border-color: #ffffff;
    }
  }
}
.el-input:focus,
.el-input-focused {
  border-color: #ffffff !important;
  box-shadow: none !important;
}
:deep(.el-input__wrapper) {
  border-radius: 16px !important;
}
.pay-btn {
  width: 190px;
  height: 41px;
  text-align: center;
  line-height: 41px;
  background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  border-radius: 21px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
}
.footer {
  text-align: center;
  text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b3b3b3;
  }
  text:nth-child(2) {
    color: #13a6e6;
    cursor: pointer;
  }
}
</style>
