<template>
  <div>
    <a-modal v-model:visible="visible" title="砍价购买" width="580px" :footer="null">
      <div class="input">
        <p>报价</p>
        <input type="text" placeholder="请输入报价" v-model="amount" />
      </div>
      <div class="title">
        <div class="line"></div>
        <p>{{ title }}</p>
      </div>
      <div class="agreement-content">
        <p v-html="content"></p>
      </div>
      <div class="footer">
        <div class="footer-btn">
          <div class="cancel" @click="cancel">取消</div>
          <div class="submit" @click="submit">提交</div>
        </div>
        <div class="footer-agreement">
          <text>支付即阅读并同意</text>
          <text @click="gopage">《交易意向金须知》</text>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, onMounted, defineExpose } from 'vue'
import { article } from '@/api/goods'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const props = defineProps({
  goodsId: String,
  price: Number,
  userinfo: Object
})
const router = useRouter()
const visible = ref(false)
const content = ref('')
const title = ref('')
const amount = ref('')
const info = ref()
function cancel() {
  visible.value = false
}
const getArticle = () => {
  article({ id: 15 }).then((res) => {
    if (res.code == 200) {
      title.value = res.data.title
      content.value = res.data.pc_content
    }
  })
}
getArticle()
const gopage = () => {
  router.push({ path: '/article', query: { id: 14 } })
}
const submit = () => {
  if (!amount.value) {
    ElMessage.warning('请输入金额')
    return
  }
  if (Number(amount.value) < props.price * Number(props.userinfo.config.bargain.offer_scale)) {
    ElMessage.warning('请重新输入金额')
    return
  }
  let money = Number(amount.value) * Number(props.userinfo.config.bargain_intention_rate)
  const params = {
    type: 6,
    amount: Number(amount.value) * 100,
    goods_id: props.goodsId,
    money: money
  }
  router.push({
    path: '/pay',
    query: { params: JSON.stringify(params), type: 'buy' }
  })
}
onMounted(() => {})
defineExpose({ visible })
</script>
<style lang="less">
.ant-modal-content {
  border-radius: 20px;
}
.ant-modal-header {
  text-align: center;
  border: 0;
  border-radius: 20px 20px 0 0;
  .ant-modal-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
  }
}
.input {
  display: flex;
  width: 530px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #0cb5ff;
  margin: 0 auto;
  p {
    width: 80px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 8px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  input {
    width: 400px;
    margin-left: 9px;
  }
}
.title {
  width: 530px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  .line {
    width: 6px;
    height: 19px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 3px;
    margin-top: 5px;
  }
  p {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-left: 8px;
  }
}
.agreement-content {
  width: 530px;
  margin: 0 auto;
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-top: 12px;
  }
}
.footer {
  margin-top: 24px;
  .footer-btn {
    display: flex;
    justify-content: center;
  }
  .cancel {
    width: 136px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    background: #f4f4f4;
    border-radius: 21px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }
  .submit {
    width: 136px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 21px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 30px;
    cursor: pointer;
  }
  .footer-agreement {
    text-align: center;
    margin-top: 20px;
    text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #b3b3b3;
    }
    text:nth-child(2) {
      color: #13a6e6;
      cursor: pointer;
    }
  }
}
</style>
